<template>
  <b-card no-body>
    <vue-apex-charts
      type="line"
      :height="window.height"
      :width="window.width"
      :options="chartConfig.chartOptions"
      :series="chartConfig.series"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  props: {
    chartXaxis: {
      type: Object,
      required: true,
    },
    chartYaxis: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartConfig: {
        series: [
          this.chartData,
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 1,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.primary],
          },
          colors: [$themeColors.primary],
          dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            background: {
              enabled: true,
              padding: 4,
              borderRadius: 2,
            },
            offsetY: -10,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            // custom(data) {
            //   return `${'<div class="px-1 py-50"><span>'}${
            //     data.series[data.seriesIndex][data.dataPointIndex]
            //   }%</span></div>`
            // },
          },
          xaxis: this.chartXaxis,
          yaxis: this.chartYaxis,
        },
      },
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = (window.innerWidth * 0.9)
      this.window.height = (window.innerHeight * 0.5)
    },
  },
}
</script>

<style scoped>

</style>
