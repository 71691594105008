import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueI18n from '@/libs/i18n/index'

export default function useSaleItem() {
  // Use toast
  const toast = useToast()
  const i18n = VueI18n

  const refSaleItemTable = ref(null)
  const today = new Date()
  const firstday = `${today.getFullYear()}-${today.getMonth() + 1}-01`
  const startDateFilter = ref(firstday)
  const lastnumber = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
  const lastday = `${today.getFullYear()}-${today.getMonth() + 1}-${lastnumber}`
  const endDateFilter = ref(lastday)
  const branchFilter = ref(null)
  const categoryFilter = ref(null)
  const saleRegionFilter = ref(null)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    refSaleItemTable.value.refresh()
  }

  const tableColumns = ref(null)
  tableColumns.value = [
    { key: 'sku', label: i18n.t('SKU'), sortable: true },
    { key: 'item_name', label: i18n.t('Item'), sortable: true },
    { key: 'total_quantity', label: i18n.t('Quantity'), sortable: true },
    { key: 'unit_name', label: i18n.t('Unit'), sortable: true },
    { key: 'price', label: i18n.t('Price'), sortable: true },
    { key: 'total_amount', label: i18n.t('Amount'), sortable: true },
    { key: 'actions', label: i18n.t('Actions') },
  ]

  watch([branchFilter, startDateFilter, endDateFilter, categoryFilter, saleRegionFilter], () => {
    refetchData()
  })

  const saleItemLabels = {
    sku: i18n.t('SKU'),
    item_name: i18n.t('Item'),
    total_quantity: i18n.t('Quantity'),
    unit_name: i18n.t('Unit'),
    price: i18n.t('Price'),
    total_amount: i18n.t('Amount'),
  }

  const saleItems = ref({
    id: null,
    sku: null,
    item_name: null,
    total_quantity: 0,
    unit_name: null,
    price: 0,
    total_amount: 0,
  })

  const fetchSaleItems = (ctx, callback) => {
    store
      .dispatch('sale_item/fetchAll', {
        subject: 'list',
        start_date: startDateFilter.value,
        end_date: endDateFilter.value,
        branch_id: branchFilter.value,
        category_id: categoryFilter.value,
        sale_region_id: saleRegionFilter.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
      })
      .then(response => {
        const { sale_items } = response.data.data
        // eslint-disable-next-line no-param-reassign
        saleItems.value = response.data.data.sale_items.map(({
          item_id, ...keepAttrs
        }) => keepAttrs)
        callback(sale_items)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting report',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSaleItems,
    saleItems,
    saleItemLabels,
    refSaleItemTable,
    refetchData,
    tableColumns,

    // Filters
    startDateFilter,
    endDateFilter,
    branchFilter,
    categoryFilter,
    saleRegionFilter,
    sortBy,
    isSortDirDesc,
  }
}
